<template>
  <div class="home page">
    <div style="background:#10ff00;height:100vh;display:flex;align-items:center;padding: 20px 0px">
      <marquee-text :duration="150"><span style="font-size:60px;background:#10ff00;color:#fff;font-weight:800">{{ msg }}</span></marquee-text>
    </div>
    <div style="position: absolute;top:20px;left: 20px;color:#000;"><router-link :to="{ name: 'index' }" exact> --- Kembali ---</router-link> </div>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import axios from 'axios'

export default {
  name: 'RunningText',
  data () {
    return {
      msg: 'Ahlan wa Sahlan'
    }
  },
  async mounted () {
    this.$store.commit('runningText/setLayout', 'full')
    if (this.$store.state.runningText.content === '') {
      const { data } = await axios.get('https://ummattv.com/api/runtext')
      this.msg = data[0][0]
      this.$store.commit('runningText/setContent', this.msg)
    } else {
      this.msg = this.$store.state.runningText.content
    }
  },
  components: {
    MarqueeText
  }
}
</script>
