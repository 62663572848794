/**
 * anything that relates to DOM
 */
export default {
  namespaced: true,
  state: {
    layout: 'full',
    /**
     * breakpoint constants
     */
    content: '',
    duration: 1
  },

  mutations: {
    setLayout (state, value) {
      state.layout = value
    },
    setContent (state, value) {
      state.content = value
    }
  }

}
