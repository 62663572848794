<template>
  <div id="app">
    <AppHeader v-if="$store.state.runningText.layout === 'default'"></AppHeader>
    <div :class="{wrapper: $store.state.runningText.layout === 'default'}">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
    <AppFooter v-if="$store.state.runningText.layout === 'default'"></AppFooter>
  </div>

</template>

<script>
import AppHeader from './Header.vue'
import AppFooter from './Footer.vue'

export default {
  name: 'AppLayout',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>s
