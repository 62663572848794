<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 18">
    <g :fill="color">
    <path d="M16.001 11a1 1 0 0 0-1 1v2.5a.5.5 0 0 1-.5.5H3.499a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h2.5a1 1 0 1 0 0-2h-4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14.002a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1z"/>
    <path d="M13.169 3.403l1.429 1.429L8.65 11H7V9.35l6.169-5.947zM13.182 1a.994.994 0 0 0-.706.292L5 8.5V13h4.501l7.209-7.475a.997.997 0 0 0 0-1.411l-2.822-2.822A.992.992 0 0 0 13.183 1z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>
